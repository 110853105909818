import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { Radio, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../reduxStore/Store';

import { useLocation, useNavigate } from 'react-router';
import { takeAssessment } from '../../../reduxStore/reducer/assessmentReducer';
import { demogQues } from '../../organisation/AddOrganisation';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
interface DemoQuestion extends demogQues {
  question: string;
  options: Array<{
    value: string;
  }>;
  isVisible: boolean;
  selectedOptions?: string | string[];
  other?: string;
}
export interface TakeDemogQues {
  gender: DemoQuestion;
  ethnicity: DemoQuestion;
  race: DemoQuestion;
  parent_degree: DemoQuestion;
  first_time_course: DemoQuestion;
  school_status: DemoQuestion;
}

const checkBoxSchema = Yup.object().shape({
  selectedOptions: Yup.array()
    .min(1, 'You must select at least one option')
    .required(),
});
const stringSchema = Yup.object().shape({
  selectedOptions: Yup.string().required('Selection is required'),
});

export default function ThirdStep(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const intitialFormValue: TakeDemogQues = state.demographics;

  const schemaForDemogQues = Yup.object().shape({
    ...(state.demographics.gender && { gender: checkBoxSchema }),
    ...(state.demographics.ethnicity && { ethnicity: checkBoxSchema }),
    ...(state.demographics.race && { race: checkBoxSchema }),
    ...(state.demographics.parent_degree && { parent_degree: stringSchema }),
    ...(state.demographics.first_time_course && {
      first_time_course: stringSchema,
    }),
    ...(state.demographics.school_status && { school_status: stringSchema }),
  });

  return (
    <Formik
      initialValues={intitialFormValue}
      validationSchema={schemaForDemogQues}
      onSubmit={(values, { setSubmitting }) => {
        state.demographics = values;

        state.type = 'demo';
        dispatch(takeAssessment(state, navigate, false, false, setSubmitting));
      }}
    >
      {({
        values,
        isValid,
        touched,
        handleChange,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <Stack
            className=''
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-end'
            spacing={3}
          >
            <Stack direction='column' spacing={5} sx={{ width: '100%' }}>
              {Object.keys(values).map((questName: string) => {
                const questDetails = values[questName as keyof TakeDemogQues];
                const isCheckBox = Array.isArray(questDetails.selectedOptions);
                return (
                  <div key={questName} className='flex flex-col gap-y-4'>
                    <h2 className='font-bold text-lg'>
                      {questDetails.question}
                    </h2>
                    <ErrorMessage
                      name={`${questName}.selectedOptions`}
                      component='div'
                      className='text-red-600'
                    />
                    <Stack className='pl-5' direction='column' spacing={1}>
                      {questDetails.options.map((optionDetails, index) => {
                        const isChecked = isCheckBox
                          ? questDetails.selectedOptions?.includes(
                              optionDetails.value
                            )
                          : questDetails.selectedOptions ===
                            optionDetails.value;

                        //if other option is selected then other text-field enabled
                        const disabled = isCheckBox
                          ? !(questDetails.selectedOptions as string[]).some(
                              (item) => item.includes('other')
                            )
                          : !questDetails?.selectedOptions?.includes('other'); // Set default to false if not an array
                        return (
                          <div
                            className='bg-slate-50 flex'
                            key={questName + index}
                          >
                            <Field
                              id={questName + index}
                              as={isCheckBox ? Checkbox : Radio}
                              size='small'
                              value={optionDetails.value}
                              name={`${questName}.selectedOptions`}
                              checked={isChecked}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChange(event);

                                //if other option checkbox unchecked then clear the text field
                                if (
                                  optionDetails.value.includes('other') &&
                                  event.target.checked == false
                                )
                                  setFieldValue(`${questName}.other`, '');
                              }}
                            />
                            <div className='bg-slate-50 flex gap-x-4 items-center max-[400px]:flex-wrap'>
                              <label
                                htmlFor={questName + index}
                                className='cursor-pointer'
                              >
                                {optionDetails.value}
                              </label>
                              {optionDetails.value.includes('other') && (
                                <Field
                                  className=''
                                  name={`${questName}.other`}
                                  as={TextField}
                                  disabled={disabled}
                                  placeholder='Other'
                                  size='small'
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Stack>
                  </div>
                );
              })}

              {/* <pre className='w-10'>{JSON.stringify(values, null, 2)}</pre>
              <pre className='w-10'>{JSON.stringify(touched, null, 2)}</pre> */}
            </Stack>
            <Button
              type='submit'
              variant='contained'
              disabled={!isValid || isSubmitting}
            >
              Next
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
