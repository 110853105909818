import { Box, Typography, Radio, Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';
import { takeAssessment } from '../../reduxStore/reducer/assessmentReducer';

import { AppContext } from '../MyAccount/Context';

const ConsentForm = () => {
  const { handleNext } = React.useContext(AppContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [button, setButton] = React.useState(false);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Typography variant='h4' gutterBottom>
          Informed Consent Form
        </Typography>
        <div className=' h-[calc(100vh-21rem)] p-2 flex flex-col gap-6 overflow-y-scroll'>
          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              Introduction
            </p>
            {/* <br /> */}
            The assessment you just completed is administered through an online
            platform called Learning About STEM Student Outcomes (LASSO). LASSO
            is an online educational tool to aid instructors and their
            institutions in tracking and evaluating student progress.
            Instructors and students from all over the United States use the
            LASSO platform in their courses. Researchers may be interested in
            using the stored assessment responses and course information for
            research projects on issues related to teaching and learning.
            <br />
            <br />
            Therefore, Dr. Ben Van Dusen, Assistant Professor at Iowa State
            University’s School of Education, is asking for your permission to
            include your assessment responses in a large dataset of responses
            from other students across the country that can be used for research
            purposes. Please carefully read through the information in this
            document before deciding whether you want to participate.
          </p>

          <p style={{ fontSize: '14px' }}>
            Participating in this research involves two steps:
            <br />
            <br />
            <p style={{ marginLeft: '5rem' }}>
              {' '}
              Step 1: Complete an online demographic questionnaire. This
              questionnaire is linked below and should take only a couple of
              minutes to complete. You will be asked to share information such
              as your gender, race, and ethnicity. Your responses on the
              demographic questionnaire will not be shared with your instructor.
            </p>
            <br />
            <p style={{ marginLeft: '5rem' }}>
              {' '}
              Step 2: Give permission for Dr. Van Dusen and his team to compile
              your responses on the assessment(s) you completed in LASSO and the
              demographic questionnaire into a dataset to be used for research
              purposes.
            </p>
            <br />
            If you give permission:
            <ul
              style={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
                marginTop: '10px',
                marginLeft: '5rem',
                padding: '0',
              }}
            >
              <li>
                The data (your responses) will be kept indefinitely and used for
                a wide variety of studies related to teaching and learning.
              </li>
              <br />

              <li>
                Some studies will be conducted by researchers at Iowa State. In
                other cases, the data will be shared with researchers at other
                institutions. Researchers must get first permission from Dr. Van
                Dusen (or his designee) and agree to use the data only for
                research and protect your confidentiality.
              </li>
              <br />

              <li>
                Any data used for research will be de-identified—that is, your
                name, email address, and college/school you attend will be
                removed before the data are shared with researchers.
                <ul
                  style={{
                    listStyleType: 'circle',
                    listStylePosition: 'inside',
                    marginLeft: '2rem',
                    padding: '0',
                  }}
                >
                  <li>
                    In rare instances, a researcher may request access to
                    identifiable information. For example, they may want to
                    invite students with certain assessment scores to
                    participate in future research. Identifiable information
                    will be made available only if there is no other feasible
                    way to carry out the research and the research has been
                    approved by an institutional review board (IRB).
                  </li>
                </ul>
              </li>
            </ul>
          </p>

          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              Benefits and Risks
            </p>
            There are no benefits to you from allowing research use of your
            information. However, future students may benefit from the knowledge
            gained through research studies using the data.
            <br />
            Dr. Van Dusen’s team will do their best to protect the data during
            storage and when they are shared. However, it is possible that
            confidential information could be accidentally revealed. To minimize
            this risk, several confidentiality protections will be employed;
            these are described below.
          </p>

          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              Your Rights{' '}
            </p>
            Allowing research use of your assessment and questionnaire responses
            is completely voluntary. You can agree or decline – it is completely
            up to you. Your choice will have no impact on your grade in your
            course, your relationship with your instructor, or any other aspect
            of your standing as a student. There are no penalties or negative
            consequences should you decline. Your course instructor will not
            know whether or not you agreed. You may skip any questions on the
            demographic questionnaire that make you uncomfortable.
            <br />
            You can also agree now but change your mind – you can email
            <a className=' text-link' href='mailto:contact@lasso.org'>
              {' '}
              contact@lasso.org
            </a>{' '}
            if you no longer wish to allow your information to be used for
            research purposes. If you change your mind, your information will be
            excluded from any future datasets that are shared. However,
            information that has already been shared for research use will
            continue to be used.
          </p>

          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              Confidentiality Protections
            </p>
            To protect your confidentiality, the research data will be stored in
            secure, university-supported systems, only authorized people have
            access to information that could directly identify you, and data
            files will be shared using secure methods.
            <br />
            <br />
            Recipient researchers must sign agreements promising to keep the
            data confidential. Researchers who receive deidentified data must
            also promise not to attempt to reidentify you. Researchers who
            receive identifiable data must have an institutional review board
            (IRB)-approved research protocol.
            <br />
            <br />
            You should know, however, that it is possible that other people and
            offices responsible for making sure research is done safely and
            responsibly will see your information. Federal government regulatory
            agencies, auditing departments of Iowa State University, and the
            Institutional Review Board (a committee that reviews and approves
            human subject research studies) may inspect and/or copy study
            records to fulfill their oversight responsibilities.
          </p>

          <p style={{ fontSize: '14px' }}>
            Results based on the LASSO data will be shared with the scientific
            community, typically through publication in journal articles or
            books or academic presentations. These results will not include any
            information that could identify you.
            <br />
            <br />
            The LASSO platform will automatically generate reports of student
            performance for different demographic groups (e.g., men and women)
            that will be shared with your instructor. These reports will share
            results for groups of students (not individual students) and will
            not contain any information that could identify you.
          </p>

          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              FERPA{' '}
            </p>
            Pursuant to the Family Educational Rights and Privacy Act (FERPA),
            your educational institution may not release your personally
            identifiable education records without your written permission
            unless another applicable exception allows for such release. With
            this understanding, you request, consent, and authorize your
            educational institution to release your protected student
            information, which includes records related to the online
            questionnaire you completed in LASSO to assess student learning in
            this course, to Dr. Ben Van Dusen at Iowa State University (ISU) for
            purposes of research.
            <br />
            <br />
            You understands:
            <ol>
              <li>
                1.Under FERPA, you have the right not to consent to the release
                of your educational records to ISU.
              </li>
              <br />

              <li>
                2.You have the right to receive a copy of such records upon your
                request to your educational institution.
              </li>
              <br />

              <li>
                3.That this consent shall remain in effect until you revoke it,
                in writing, delivered to Dr. Ben Van Dusen, but that any such
                revocation shall not affect disclosures previously made to ISU
                prior to the receipt of your written revocation.
              </li>
              <br />

              <li>
                4.Your electronic acceptance of this form will be treated as
                your signature for purposes of FERPA consent.
              </li>
              <br />

              <li>
                5.That once released to ISU, your educational institution does
                not have the ability to control the information, and ISU may
                re-disclose the information. It is the sole responsibility of
                ISU to comply with all applicable laws and regulations regarding
                re-disclosure of the information.
              </li>
            </ol>
          </p>

          <p style={{ fontSize: '14px' }}>
            <p
              style={{
                fontSize: '17px',
                color: 'rgb(2 109 200)',
                fontWeight: '300',
              }}
            >
              Questions
            </p>
            For questions about the LASSO research project, you may contact Dr.
            Ben Van Dusen via email at{' '}
            <a className=' text-link' href='mailto:contact@lassoeducation.org'>
              {' '}
              contact@lassoeducation.org
            </a>
            , or by phone at 515-294-0317.
            <br />
            If you have any questions about the rights of research subjects or
            research-related injury, please contact the IRB Administrator, (515)
            294-4566,{' '}
            <a className=' text-link' href='mailto:IRB@iastate.edu'>
              {' '}
              IRB@iastate.edu{' '}
            </a>
            , or Director, (515) 294-3115, Office of Research Ethics, Iowa State
            University, Ames, Iowa 50011.
          </p>
        </div>

        <div className=''>
          <Formik
            initialValues={{
              picked: '1',
            }}
            onSubmit={(values) => {
              state.participation = values.picked;

              state.type = 'consent';
              dispatch(
                takeAssessment(state, navigate, false, false, setButton)
              );
            }}
          >
            {({ values }) => (
              <Form>
                <div>
                  <p style={{ fontSize: '14px' }}>
                    <p
                      style={{
                        fontSize: '17px',
                        color: 'rgb(2 109 200)',
                        fontWeight: '300',
                      }}
                    >
                      Your Consent{' '}
                    </p>
                    {/* <br /> */}
                    Please click below to indicate whether you are willing to
                    allow your LASSO assessment and demographic questionnaire
                    responses to be used for research purposes.
                  </p>
                </div>
                <div
                  className='flex flex-col'
                  role='group'
                  aria-labelledby='my-radio-group'
                >
                  <label style={{ display: 'flex' }}>
                    <Field
                      as={Radio}
                      checked={values.picked == '1'}
                      type='radio'
                      size='small'
                      name='picked'
                      value='1'
                    />
                    <p
                      style={{
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      YES – I give permission for my LASSO assessment and
                      questionnaire responses to be retained indefinitely and
                      used for research purposes. I also certify I am 18 years
                      of age or older, and that I have read and understand the
                      information above.
                    </p>
                  </label>
                  <label style={{ display: 'flex' }}>
                    <Field
                      as={Radio}
                      type='radio'
                      size='small'
                      name='picked'
                      value='2'
                      checked={values.picked == '2'}
                    />
                    <p
                      style={{
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      NO – I do not give permission for research use of my LASSO
                      assessment and questionnaire responses.
                    </p>
                  </label>

                  {/* <div>Picked: {values.picked}</div> */}
                </div>
                <div className='mt-1'>
                  <Button
                    sx={{ textTransform: 'capitalize' }}
                    size='small'
                    variant='contained'
                    type='submit'
                    disabled={button}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </div>
  );
};

export default ConsentForm;
