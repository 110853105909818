import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import StepForm from './SteperForm';
import { StepsProvider } from '../../register/stepper/Context';
import { useDispatch } from 'react-redux';
import { getUserAndOrganizationById } from '../../../reduxStore/reducer/registerReducer';

import { AppDispatch } from '../../../reduxStore/Store';
import { useLocation } from 'react-router-dom';

const theme = createTheme();

const Index = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getUserAndOrganizationById({ id: id }, handleOpen));
  }, [dispatch]);
  return (
    <>
      {open ? (
        <StepsProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
              <Paper
                variant='outlined'
                sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              >
                <StepForm />
              </Paper>
            </Container>
          </ThemeProvider>
        </StepsProvider>
      ) : (
        ''
      )}
    </>
  );
};

export default Index;
