import Box from '@mui/material/Box';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { Avatar } from '@mui/material';

function LinkExpired() {
  return (
    <Box
      sx={{
        background:
          'radial-gradient(circle, rgba(243,239,255,1) 21%, rgba(243,252,244,1) 100%)',
      }}
      className='h-screen flex gap-3 flex-col justify-center items-center'
    >
      <Avatar className='p-10 mb-3' sx={{ backgroundColor: '#F8FBFC' }}>
        <TimerOffIcon fontSize='large' color='warning' />
      </Avatar>
      <h1 className='text-6xl font-extrabold'>Expired Link</h1> <br />
      <p className='font-mono'>
        This link has expired. Please contact the admin to resend this link{' '}
      </p>
      <p className='font-mono'>You can close this window</p>
    </Box>
  );
}

export default LinkExpired;
