import React, { useState, useCallback, useRef } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';
import { studentFileupload } from '../../reduxStore/reducer/courseReducer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const FileDropDialog = ({ props }: any) => {
  const [file, setFile] = useState<File | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  // Open and close modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0];
    processFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFileChange = async (e: any) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    processFile(file);
  };

  const processFile = async (file: File) => {
    const allowedExtensions = ['.csv'];
    const fileName = file.name;
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire('Invalid File', 'Please select a CSV file.', 'error');
      setIsButtonDisabled(true); // Disable upload button
      return;
    }

    // Parse the file and ensure "\r" at the end of rows
    const fileContent = await file.text();
    const rows = fileContent.split('\n').map((row) => row.trim());
    const correctedRows = rows.map((row) =>
      row.endsWith('\r') ? row : `${row}\r`
    );
    const correctedContent = correctedRows.join('\n');

    // Convert corrected content back to a Blob
    const correctedBlob = new Blob([correctedContent], { type: 'text/csv' });
    const correctedFile = new File([correctedBlob], fileName, {
      type: 'text/csv',
    });

    setFile(correctedFile);
    setIsButtonDisabled(false); // Enable upload button
  };

  const handleUploadClick = () => {
    const originalFiles: any = file;
    const formData = new FormData();

    formData.append('file', originalFiles);
    console.log(props?.data?.id);
    formData.append('courseid', props?.data?.id);

    const callback = () => {
      inputRef.current && (inputRef.current.value = '');
      setIsButtonDisabled(true);
      setFile(null);

      props?.getData();
      props?.setRefresh && props.setRefresh(true);
      setTimeout(() => {
        props?.setRefresh && props.setRefresh(false);
      }, 1000);
      props?.setStudentState(false);
    };

    studentFileupload(formData, callback); // Replace with your upload function
  };

  return (
    <>
      <Button
        sx={{ textTransform: 'capitalize' }}
        variant='contained'
        size='small'
        startIcon={<AddCircleIcon />}
        className={props?.btnStyle}
        onClick={handleOpen}
        disabled={props.migrated}
      >
        <label htmlFor='upload' className='relative top-2'>
          Add Course Roster
          <div className='text-[10px] text-yellow-200 italic text-start lowercase relative bottom-2'>
            *only csv
          </div>
        </label>
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            textAlign: 'center',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            startIcon={<CloudUploadIcon />}
            onClick={handleUploadClick}
            sx={{ mt: 2 }}
            disabled={isButtonDisabled}
          >
            Upload File
          </Button>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed p-6 rounded-xl text-center my-5 ${
              isDragActive ? 'bg-blue-100' : 'bg-gray-100'
            }`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <p>Drag & drop some files here, or click to select files</p>
            )}
          </div>

          {file && (
            <Typography my={2} variant='body1'>
              Selected File: <span className='font-bold'>{file?.name}</span>
            </Typography>
          )}
          <Button
            variant='contained'
            startIcon={<CloudDownloadIcon />}
            onClick={props?.onBtnExport}
            size='large'
            className={props?.btnStyle}
          >
            Download Roster Template
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default FileDropDialog;
